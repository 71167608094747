<template>
  <div>
    <div class="card h-full">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.audience_location.title') }}
          </div>
          <small>
            {{ $t('page.analytics.audience_location.description') }}
          </small>
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.audience_location.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body">
        <div v-if="hasData" class="flex flex-col space-y-8 my-6">
          <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useNumeral } from '@/composables/useNumeral';
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n';
import VueApexCharts from "vue3-apexcharts";
import BasePopover from '../base/BasePopover.vue';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { t } = useI18n()

    const {
      counter
    } = useNumeral()
    
    const cities = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).extra) {
        const insights = unref(socialNetworkData).extra
        if (insights && insights['audience_city']) {
          const cities = insights['audience_city'].values[0].value
          return Object.keys(cities)
            .map(city => ({
              name: city,
              count: cities[city]
            }))
            .sort((a, b) => b.count - a.count)
            .slice(0, 10)
        }
      }
      return []
    })

    const hasData = computed(() => {
      return unref(cities) && Object.keys(unref(cities)).length
    })

    const series = computed(() => {
      return [{
        data: unref(cities).map(city => city.count)
      }]
    })

    const chartOptions = computed(() => {
      const formatter = (value: number) => {
        if (typeof value !== "undefined") {
          return counter(value)
        }
        return value
      }

      return {
        chart: {
          type: 'bar',
          height: 350
        },
        labels: unref(cities).map(city => city.name),
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            formatter
          }
        },
        tooltip: {
          x: {
            show: true
          },
          y: {
            title: {
              formatter: function () {
                return t('sentence.followers')
              }
            },
            formatter
          }
        }
      }
    })

    return {
      hasData,
      series,
      chartOptions,
    }
  }
})
</script>
