<template>
  <dashboard-content>
    <template v-slot:actions>
      <analytics-page-menu v-model="selectedNetworkName"/>
    </template>

    <div v-if="isLoaded && hasSelectedSocialNetwork">
      <div class="my-12">
        <basic-info :socialNetworkData="selectedNetworkData" />
      </div>
      
      <div class="my-12">
        <h2>{{ $t('page.analytics.session_social_networks.sentences.title') }}</h2>
        <p>{{ $t('page.analytics.session_social_networks.sentences.description') }}</p>
      </div>

      <div class="my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <engagement-chart :analytics="analytics" />
        <network-distribution-chart :analytics="analytics" />
      </div>

      <div class="my-12">
        <h2>{{ $t('page.analytics.session_behavior.sentences.title') }}</h2>
        <p>{{ $t('page.analytics.session_behavior.sentences.description') }}</p>
      </div>

      <div class="my-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <!-- <followers-count :socialNetworkData="selectedNetworkData" /> -->
        <engagement-rate :socialNetworkData="selectedNetworkData" />
        <!-- <content-count :socialNetworkData="selectedNetworkData" /> -->
        <comments-count :socialNetworkData="selectedNetworkData" />

        <likes-count :socialNetworkData="selectedNetworkData" />
        <reach-count :socialNetworkData="selectedNetworkData" />
        <impressions-count :socialNetworkData="selectedNetworkData" />
        <saved-content-count :socialNetworkData="selectedNetworkData" />
      </div>

      <div class="my-4">
        <followers-metrics :socialNetworkData="selectedNetworkData" :socialNetworkName="selectedNetworkName" />
      </div>

      <div class="my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <MediaTypeDistributionChart :socialNetworkData="selectedNetworkData" />
        <MediaTypeEngagementChart :socialNetworkData="selectedNetworkData" />
      </div>


      <div class="my-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
        <reaction-to-content :socialNetworkData="selectedNetworkData" />
        <history-of-publication-times
          class="lg:col-span-2"
          :socialNetworkData="selectedNetworkData"
          :socialNetworkName="selectedNetworkName" />
      </div>

      <div
        class="my-4"
        v-if="selectedNetworkName === 'instagram'"
      >
        <audience-age :socialNetworkData="selectedNetworkData" />
      </div>
      
      <div
        class="my-4 grid grid-cols-1 md:grid-cols-3 gap-4"
        v-if="selectedNetworkName === 'instagram'"
      >
        <audience-gender :socialNetworkData="selectedNetworkData"
        />
        <audience-city
          class="md:col-span-2"
          :socialNetworkData="selectedNetworkData"
        />
      </div>

      <div class="my-12">
        <h2>{{ $t('page.analytics.session_hashtags.sentences.title') }}</h2>
        <p>{{ $t('page.analytics.session_hashtags.sentences.description') }}</p>
      </div>

      <div class="my-4">
        <history-of-hashtags :socialNetworkData="selectedNetworkData" />
      </div>

      <div class="my-12">
        <h2>{{ $t('page.analytics.session_performance.sentences.title') }}</h2>
      </div>

      <div class="my-4">
        <best-posts :socialNetworkData="selectedNetworkData" />
      </div>

      <div class="my-12" v-if="selectedNetworkName === 'instagram'">
        <h2>Stories</h2>
      </div>

      <template v-if="selectedNetworkName === 'instagram'">
        <div class="my-12">
          <stories-basic-info :socialNetworkData="selectedNetworkData" />
        </div>

        <div class="my-4">
          <stories :socialNetworkData="selectedNetworkData" />
        </div>
      </template>

      <div class="my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <competitors :socialNetwork="selectedNetworkName" />
        <engaged-followers
          :socialNetworkData="selectedNetworkData"
          :socialNetworkName="selectedNetworkName"
        />
      </div>
    </div>

    <div
      class="card p-4 flex flex-col justify-center items-center mx-auto h-72 w-full lg:w-3/4"
      v-if="isLoaded && isEmpty"
    >
      <div class="text-lg text-center leading-relaxed" v-if="lockAccess">
        <p class="text-base font-semibold leading-snug">
          <i class="fas fa-info-circle text-blue-400"></i>
          {{ $t('page.review.sentence.unavailable_resource') }}
        </p>

        <offer-modal offer="analyticsOffer" :startActive="false">
          <template v-slot:button >
            <button
              class="btn btn-primary mt-12 mx-auto"
              @click.prevent="toggleOfferModal"
            >
              {{ $t('page.review.action.unavailable_resource') }}
            </button>
          </template>
        </offer-modal>
      </div>

      <div class="text-lg text-center leading-relaxed" v-else>
        <p
          v-html="$t('page.analytics.sentence.no_social_account-html')"
        />
        <router-link
          class="btn btn-primary mt-12"
          :to="{ name: 'settings-social-medias' }"
        >
          {{ $t('action.connect_social_accounts') }}
        </router-link>
      </div>

      <div class="text-sm text-center mt-12">
        <base-you-need-help-message />
      </div>
    </div>

    <div
      class="flex flex-col justify-center items-center mx-auto"
      v-if="!isLoaded"
    >
      <div class="my-24">
        <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
      </div>
    </div>
    
    <template
      v-slot:notification
      v-if="lockAccess"
    >
      <offer-alert-wrapper>
        {{ alertMessage }}
      </offer-alert-wrapper>
      <offer-modal offer="analyticsOffer" hide-button/>
    </template>
  </dashboard-content>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import AnalyticsPageMenu from '@/components/analytics/AnalyticsPageMenu.vue'
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import EngagementChart from '@/components/analytics/EngagementChart.vue'
import NetworkDistributionChart from '@/components/analytics/networkDistributionChart.vue'
// import FollowersCount from '@/components/analytics/FollowersCount.vue'
import ReachCount from '@/components/analytics/ReachCount.vue'
import ImpressionsCount from '@/components/analytics/ImpressionsCount.vue'
import SavedContentCount from '@/components/analytics/SavedContentCount.vue'
// import ContentCount from '@/components/analytics/ContentCount.vue'
import EngagementRate from '@/components/analytics/EngagementRate.vue'
import CommentsCount from '@/components/analytics/CommentsCount.vue'
import LikesCount from '@/components/analytics/LikesCount.vue'
import ReactionToContent from '@/components/analytics/ReactionToContent.vue'
import HistoryOfPublicationTimes from '@/components/analytics/historyOfPublicationTimes.vue'
import HistoryOfHashtags from '@/components/analytics/HistoryOfHashtags.vue'
import FollowersMetrics from '@/components/analytics/FollowersMetrics.vue'
import MediaTypeDistributionChart from '@/components/analytics/MediaTypeDistributionChart.vue'
import MediaTypeEngagementChart from '@/components/analytics/MediaTypeEngagementChart.vue'
import AudienceGender from '@/components/analytics/AudienceGender.vue'
import AudienceAge from '@/components/analytics/AudienceAge.vue'
import AudienceCity from '@/components/analytics/AudienceCity.vue'
import BestPosts from '@/components/analytics/BestPosts.vue'
import Competitors from '@/components/analytics/Competitors.vue'
import EngagedFollowers from '@/components/analytics/EngagedFollowers.vue'
import BasicInfo from '@/components/analytics/BasicInfo.vue'
import StoriesBasicInfo from '@/components/analytics/StoriesBasicInfo.vue'
import Stories from '@/components/analytics/Stories.vue'
import { useProfile } from '@/composables/profile/useProfile'
import OfferModal from '@/components/freeTrial/OfferModal.vue'
import OfferAlertWrapper from '@/components/freeTrial/OfferAlertWrapper.vue'
import { useI18n } from 'vue-i18n'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'
import { useStore } from 'vuex'

interface IAnalytics {
  facebook: string
  instagram: string
  linkedin: string
  twitter: string
}

export default defineComponent({
  components: {
    DashboardContent,
    AnalyticsPageMenu,
    EngagementChart,
    NetworkDistributionChart,
    MediaTypeDistributionChart,
    MediaTypeEngagementChart,
    // FollowersCount,
    ReachCount,
    ImpressionsCount,
    SavedContentCount,
    // ContentCount,
    EngagementRate,
    CommentsCount,
    LikesCount,
    ReactionToContent,
    HistoryOfPublicationTimes,
    HistoryOfHashtags,
    FollowersMetrics,
    AudienceGender,
    AudienceAge,
    AudienceCity,
    BestPosts,
    Competitors,
    EngagedFollowers,
    OfferModal,
    OfferAlertWrapper,
    BaseYouNeedHelpMessage,
    BasicInfo,
    StoriesBasicInfo,
    Stories
},

  setup () {
    const selectedNetworkName = ref<string>('')

    const { t } = useI18n()

    const store = useStore()

    const {
      analytics,
      isLoaded,
      isEmpty
    } = getAnalytics()

    const {
      isFreeTrial,
      isFreeTrialActive,
      hasActiveSubscription,
      lockAccess,
      isActive
    } = useProfile()

    watchEffect(() => {
      store.dispatch('analytics/fetchAnalytics', isActive.value)
    })

    const selectedNetworkData = computed(() => {
      if (unref(selectedNetworkName) === '') return

      const key = unref(selectedNetworkName) as keyof IAnalytics
      return unref(analytics)[key]
    })

    const hasSelectedSocialNetwork = computed(() => {
      return unref(selectedNetworkName) !== ''
    })

    const alertMessage = computed(() => {
      if (isFreeTrial.value && !isFreeTrialActive.value) {
        return t('state.finish_free_trial')
      } else if (!hasActiveSubscription.value) {
        return t('state.subscription_expired')  
      }
      return ''
    })

    return {
      hasSelectedSocialNetwork,
      selectedNetworkName,
      selectedNetworkData,
      analytics,
      isLoaded,
      isEmpty,
      alertMessage,
      lockAccess
    }
  }
})
</script>
