
import { computed, defineComponent, toRefs, unref } from 'vue'
import VueApexCharts from "vue3-apexcharts";
import { subMonths } from 'date-fns'
import { useDateFns } from '@/composables/locale/useDateFns'
import socialNetworkColor from '@/composables/socialNetworks/socialNetworkColor'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    apexchart: VueApexCharts
  },

  props: {
    analytics: {
      type: Object,
      default: () => ({})
    },
  },

  setup (props) {

    const { t } = useI18n()

    const { analytics } = toRefs(props)

    const { format } = useDateFns()

    const interval = computed(() => {
      const currentMonth = format(new Date(), 'MMMM/yyyy')
      const startMonth = format(subMonths(new Date(), 6), 'MMMM/yyyy')
      return `${startMonth} - ${currentMonth}`
    })

    const engagementEvolution = computed(() => {
      return Object.keys(unref(analytics)).map(socialNetwork => {
        if (unref(analytics)[socialNetwork].engagementEvolution && unref(analytics)[socialNetwork].engagementEvolution.length) {
          return {
            name: socialNetwork.toUpperCase(),
            data: unref(analytics)[socialNetwork].engagementEvolution.map((g: any) => g.sum),
            labels: unref(analytics)[socialNetwork].engagementEvolution.map((g: any) => g.month),
            color: socialNetworkColor(socialNetwork)
          }
        }
        return null
      }).filter(r => r !== null)
    })

    const dates = computed(() => {
      if (unref(engagementEvolution) && unref(engagementEvolution).length) {
        return unref(engagementEvolution)[0]?.labels
          .map((e: string) => format(new Date(`${e.substring(0, 4)}/${e.substring(4, 6)}/01`), 'MMM'))
      } else {
        return []
      }
    })

    const series = computed(() => {
      return unref(engagementEvolution)
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: unref(engagementEvolution).map((g: any) => g.color),
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: t('page.analytics.engagement.chart.title'),
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        labels: unref(dates),
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    })

    return {
      interval,
      series,
      chartOptions
    }
  }
})
