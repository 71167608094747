
import { useNumeral } from '@/composables/useNumeral'
import { computed, defineComponent, toRefs, unref } from 'vue'
import BasePopover from '../base/BasePopover.vue'

export default defineComponent({
  components: { BasePopover },
  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const {
      format
    } = useNumeral()
    
    const hasData = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).sentment) {
        const sentment = unref(socialNetworkData).sentment
        return sentment.total > 0
      }
      return false
    })

    const sentment = computed(() => {
      const result = {
        positive: {
          value: 0,
          percent: 0
        },
        negative: {
          value: 0,
          percent: 0
        },
        null: {
          value: 0,
          percent: 0
        }
      }
      if (unref(hasData)) {
        const sentment = unref(socialNetworkData).sentment
        if (sentment.total > 0) {
          result.positive.value = sentment.positive
          result.positive.percent = ((result.positive.value) / sentment.total)
          result.negative.value = sentment.negative
          result.negative.percent = ((result.negative.value) / sentment.total)
          result.null.value = sentment.mixed + sentment.null
          result.null.percent = ((result.null.value) / sentment.total)
        }
      }
      return result
    })

    return {
      hasData,
      sentiment: sentment,
      format
    }
  }
})
