<template>
  <div>
    <div class="card h-full">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.audience_gender.title') }}
          </div>
          <small>
            {{ $t('page.analytics.audience_gender.description') }}
          </small>
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.audience_gender.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body">
        <div v-if="hasData" class="flex flex-col space-y-8 my-6">
          <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useNumeral } from '@/composables/useNumeral';
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n';
import VueApexCharts from "vue3-apexcharts";
import BasePopover from '../base/BasePopover.vue';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { t } = useI18n()

    const {
      counter
    } = useNumeral()
    
    const genders = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).extra) {
        const insights = unref(socialNetworkData).extra
        if (insights && insights['audience_gender_age']) {
          return insights['audience_gender_age'].values[0].value
        }
      }
      return []
    })

    const hasData = computed(() => {
      return unref(genders) && Object.keys(unref(genders)).length
    })

    const series = computed(() => {
      const result = [0, 0]
      Object.keys(unref(genders)).map(key => {
        const gender = key.split('.')[0]
        if (gender === 'M') {
          result[0] = result[0] + unref(genders)[key]
        }
        if (gender === 'F') {
          result[1] = result[1] + unref(genders)[key]
        }
      })
      return [{
        data: result
      }] 
    })

    const chartOptions = computed(() => {
      const formatter = (value: number) => {
        if (typeof value !== "undefined") {
          return counter(value)
        }
        return value
      }

      return {
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#008ffb', '#d10ce8'],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          formatter
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
            [t('sentence.male')],
            [t('sentence.feminine')]
          ],
          labels: {
            style: {
              colors: ['#008ffb', '#d10ce8'],
              fontSize: '12px'
            },
          }
        },
        yaxis: {
          labels: {
            formatter
          }
        },
        tooltip: {
          x: {
            show: true
          },
          y: {
            title: {
              formatter: function () {
                return t('sentence.followers')
              }
            },
            formatter
          }
        }
      }
    })
    
    return {
      hasData,
      series,
      chartOptions,
    }
  }
})
</script>
