
import { computed, defineComponent, toRefs, unref } from 'vue'
import VueApexCharts from "vue3-apexcharts";
import { useDateFns } from '@/composables/locale/useDateFns'
import socialNetworkColor from '@/composables/socialNetworks/socialNetworkColor'

export default defineComponent({
  components: {
    apexchart: VueApexCharts
  },

  props: {
    analytics: {
      type: Object,
      default: () => ({})
    },
  },
  
  setup (props) {

    const { analytics } = toRefs(props)

    const { format } = useDateFns()
    
    const currentMonth = computed(() => {
      return format(new Date(), 'MMMM/yyyy')
    })

    const networkDistribuition = computed(() => {
      return Object.keys(unref(analytics)).map(socialNetwork => {
        if (unref(analytics)[socialNetwork].fans >= 0) {
          return {
            source: socialNetwork,
            count: unref(analytics)[socialNetwork].fans as number
          }
        }
        return null
      }).filter(r => r !== null)
    })

    const series = computed(() => {
      return unref(networkDistribuition).map(data => data?.count || 0)
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          type: 'donut'
        },
        colors: unref(networkDistribuition).map(data => socialNetworkColor(data?.source)),
        labels: unref(networkDistribuition).map(data => data?.source.toUpperCase()),
        legend: {
          formatter: (val: any, opts: any) => {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex]}`
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    })

    return {
      currentMonth,
      series,
      chartOptions
    }
  }
})
