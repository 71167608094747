<template>
  <div class="flex flex-row justify-start py-4">
    <div>
      <img
        class="flex-none w-14 h-14 rounded-full object-cover shadow-lg mx-4"
        :src="avatar"
        @error="imageFallback"
      >
    </div>
    <div class="truncate">
      <h5 class="text-xl">{{ username }}</h5>
      <div class="flex">
        <div>
          <i class="fas fa-comment text-blue-600"></i> {{account.comments}}
        </div>
        <div class="ml-4" v-if="socialNetworkName !== 'instagram'">
          <i class="fas fa-heart text-red-500"></i> {{account.likes}}
        </div>
      </div>       
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, unref } from 'vue'
import instagramApi from '@/services/instagram'

export default defineComponent({
  props: {
    account: {
      type: Object,
      default: () => ({})
    },
    socialNetworkName: {
      type: String,
      default: ''
    }
  },

  setup (props) {
    const { account, socialNetworkName } = toRefs(props)
    const avatar = ref('')

    const username = computed(() => {
      return unref(socialNetworkName) === 'instagram'
        ? `@${unref(account).username}`
        : unref(account).name
    })

    onMounted(async() => {
      if (unref(socialNetworkName) === 'instagram') {
        if (!unref(account).profile_pic_url) {
          const currentAvatar = await instagramApi.getAccountAvatar(unref(account).username)
          avatar.value = currentAvatar ? currentAvatar : require('@/assets/profile/manyrobot-profile.svg')
        } else {
          avatar.value = unref(account).profile_pic_url
        }
      }
    })

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')
    }

    return {
      avatar,
      username,
      imageFallback
    }
  }
})
</script>
