<template>
  <div class="flex flex-row justify-start py-4 space-x-4">
    <div class="flex justify-center">
      <img
        class="flex-none w-14 lg:w-20 h-14 lg:h-20 rounded-full object-cover shadow-lg mx-4"
        :src="account.image"
      >
    </div>
    <div class="w-full">
      <h4 class="text-xl">@{{account.username}}</h4>
      <div class="grid grid-rows-3 grid-cols-none md:grid-cols-3 md:grid-rows-none my-4">
        <div class="flex md:flex-col items-center justify-between">
          <div>{{ $t('sentence.followers') }}</div>
          <strong>{{ followers }}</strong>
        </div>
        <div class="flex md:flex-col items-center justify-between">
          <div>{{ $t('sentence.following') }}</div>
          <strong>{{ following }}</strong>
        </div>
        <div class="flex md:flex-col items-center justify-between">
          <div>{{ $t('sentence.contents') }}</div>
          <strong>{{ posts }}</strong>
        </div>
      </div>
      <div
        class="border rounded p-4 text-center engagement_rate"
        :class="[ account.insideRange
          ? 'border-green-400 text-green-400'
          : 'border-red-600 text-red-600']"
        >
        <span v-html="$t('sentence.engagement_rate-dynamic', { engagementRate })"/> %
        <br>
        <span v-if="account.insideRange" style="color: inherit">
          {{ $t('sentence.within_average') }}
        </span>
        <span v-else style="color: inherit">
          {{ $t('sentence.below_average') }}
        </span>
        <button @click.prevent="showLegend()">
          <i class="far fa-question-circle ml-2 text-blue-600"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Swal from "sweetalert2"
import { useNumeral } from '@/composables/useNumeral'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    account: {
      type: Object,
      default: () => ({})
    }
  },

  setup (props) {

    const {
      counter,
      format
    } = useNumeral()

    const { t } = useI18n()

    const showLegend = () => {
      Swal.fire({
        confirmButtonText: t('state.engagement_description.action_confirm'),
        html: t('state.engagement_description.message-html'),
      })
    }

    const engagementRate = computed(() => {
      return format(props.account.engagementRate, `0.00`)
    })

    const followers = computed(() => {
      return counter(props.account.followers)
    })

    const posts = computed(() => {
      return counter(props.account.posts)
    })

    const following = computed(() => {
      return counter(props.account.following)
    })

    return {
      showLegend,
      engagementRate,
      followers,
      following,
      posts
    }
  }
})
</script>

<style scoped>
.engagement_rate :deep(span),
.engagement_rate :deep(strong) {
  color: inherit;
}
</style>
