<template>
  <div>
    <div
      class="flex flex-row space-x-2 md:space-x-0"
      v-if="showSocialNetworksButtons"
    >
      <button
        v-for="socialNetwork in socialNetworks"
        :key="socialNetwork.label"
        class="select-button"
        :class="{ active: socialNetwork.active }"
        :disabled="socialNetwork.disabled"
        @click="onChange(socialNetwork.label)"
      >
        <i
          class=""
          :class="socialNetwork.icon"
          :style="{ color: socialNetwork.color}"
        >
        </i>
        <span class="hidden lg:block">{{ socialNetwork.label }}</span>
      </button>
    </div>
    <div
      v-if="showConnectButton"
    >
      <router-link :to="{ name: 'settings-social-medias' }" class="btn btn-primary">
        {{ $t('action.connect_social_accounts') }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, unref, watch } from 'vue'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },

  emits: [
    'update:modelValue'
  ],

  setup (props, {emit}) {
    const router = useRouter()
    const { modelValue } = toRefs(props)
    const availables = ref<string[]>([])
    const isLoaded =  ref<boolean>(false)

    const { t } = useI18n()

    const {
      getAvailables
    } = useSocialAccounts()

    const {
      isFreeTrial,
      isFreeTrialActive
    } = useProfile()

    onMounted( async () => {
      try {
        const items = await getAvailables()
        availables.value = ['instagram', 'facebook', 'linkedin', 'twitter'].filter(item => items.includes(item))
        emit('update:modelValue', unref(availables).length ? unref(availables)[0] : '')
        
        isLoaded.value = true
      } catch (error) {
        isLoaded.value = false
      }
    })

    const showSocialNetworksButtons = computed(() => {
      return unref(isLoaded) && unref(availables).length
    })

    const showConnectButton = computed(() => {
      return unref(isLoaded) 
        && !unref(availables).length
        && (!isFreeTrial.value || isFreeTrial.value && isFreeTrialActive.value)
    })

    watch(isLoaded, (newValue) => {
      if (newValue && unref(showConnectButton)) {
          Swal.fire({
            showCancelButton: true,
            confirmButtonText: t('state.connect_social_accounts.action_confirm'),
            cancelButtonText: t('state.connect_social_accounts.action_cancel'),
            html: t('state.connect_social_accounts.message-html')
          }).then(async ({ value }) => {
            if (value) {
              router.push({ name: 'settings-social-medias' })
            }
          })
      }
    })

    const socialNetworks = computed(() => {
      return [
        {
          icon: 'fab fa-instagram text-lg',
          color: '#e4405f',
          label: 'instagram',
          active: unref(modelValue) === 'instagram',
          disabled: !unref(availables).includes('instagram')
        },
        {
          icon: 'fab fa-facebook-f text',
          color: '#3b5999',
          label: 'facebook',
          active: unref(modelValue) === 'facebook',
          disabled: !unref(availables).includes('facebook')
        },
        {
          icon: 'fab fa-linkedin text-lg',
          color: '#0077b5',
          label: 'linkedin',
          active: unref(modelValue) === 'linkedin',
          disabled: !unref(availables).includes('linkedin')
        },
        {
          icon: 'fab fa-twitter text-lg',
          color: '#55acee',
          label: 'twitter',
          active: unref(modelValue) === 'twitter',
          disabled: !unref(availables).includes('twitter')
        }
      ]
    })

    const onChange = (currentSocialNetwork: string) => {
      emit('update:modelValue', currentSocialNetwork)
    }

    return {
      socialNetworks,
      onChange,
      showSocialNetworksButtons,
      showConnectButton
    }
  }
})
</script>

<style scoped>
.select-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 9999px;
  background-color: transparent;
  text-transform: capitalize;
  --tw-text-opacity: 1;
  color: rgba(82, 82, 91, var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 2rem;
  height: 2rem;
}
.select-button > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.select-button:disabled {
  cursor: not-allowed;
}
.select-button:disabled *, .select-button:disabled i {
  --tw-text-opacity: 1 !important;
  color: rgba(161, 161, 170, var(--tw-text-opacity)) !important;
}
.select-button.active {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
@media (min-width: 1024px) {
  .select-button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
    height: 3rem;
  }
}
</style>
