
import { computed, defineComponent, onMounted, ref, toRefs, unref, watch } from 'vue'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },

  emits: [
    'update:modelValue'
  ],

  setup (props, {emit}) {
    const router = useRouter()
    const { modelValue } = toRefs(props)
    const availables = ref<string[]>([])
    const isLoaded =  ref<boolean>(false)

    const { t } = useI18n()

    const {
      getAvailables
    } = useSocialAccounts()

    const {
      isFreeTrial,
      isFreeTrialActive
    } = useProfile()

    onMounted( async () => {
      try {
        const items = await getAvailables()
        availables.value = ['instagram', 'facebook', 'linkedin', 'twitter'].filter(item => items.includes(item))
        emit('update:modelValue', unref(availables).length ? unref(availables)[0] : '')
        
        isLoaded.value = true
      } catch (error) {
        isLoaded.value = false
      }
    })

    const showSocialNetworksButtons = computed(() => {
      return unref(isLoaded) && unref(availables).length
    })

    const showConnectButton = computed(() => {
      return unref(isLoaded) 
        && !unref(availables).length
        && (!isFreeTrial.value || isFreeTrial.value && isFreeTrialActive.value)
    })

    watch(isLoaded, (newValue) => {
      if (newValue && unref(showConnectButton)) {
          Swal.fire({
            showCancelButton: true,
            confirmButtonText: t('state.connect_social_accounts.action_confirm'),
            cancelButtonText: t('state.connect_social_accounts.action_cancel'),
            html: t('state.connect_social_accounts.message-html')
          }).then(async ({ value }) => {
            if (value) {
              router.push({ name: 'settings-social-medias' })
            }
          })
      }
    })

    const socialNetworks = computed(() => {
      return [
        {
          icon: 'fab fa-instagram text-lg',
          color: '#e4405f',
          label: 'instagram',
          active: unref(modelValue) === 'instagram',
          disabled: !unref(availables).includes('instagram')
        },
        {
          icon: 'fab fa-facebook-f text',
          color: '#3b5999',
          label: 'facebook',
          active: unref(modelValue) === 'facebook',
          disabled: !unref(availables).includes('facebook')
        },
        {
          icon: 'fab fa-linkedin text-lg',
          color: '#0077b5',
          label: 'linkedin',
          active: unref(modelValue) === 'linkedin',
          disabled: !unref(availables).includes('linkedin')
        },
        {
          icon: 'fab fa-twitter text-lg',
          color: '#55acee',
          label: 'twitter',
          active: unref(modelValue) === 'twitter',
          disabled: !unref(availables).includes('twitter')
        }
      ]
    })

    const onChange = (currentSocialNetwork: string) => {
      emit('update:modelValue', currentSocialNetwork)
    }

    return {
      socialNetworks,
      onChange,
      showSocialNetworksButtons,
      showConnectButton
    }
  }
})
