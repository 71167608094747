

import { computed, defineComponent, toRefs, unref } from 'vue'
import { useNumeral } from '@/composables/useNumeral'
import { getAnalytics } from '@/composables/analytics/useAnalytics';

export default defineComponent({
  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { getStoriesInsights } = getAnalytics();

    const {
      counter
    } = useNumeral()

    const basicInfo = computed(() => {
      const account = unref(socialNetworkData)
      const stories = account.tmp?.stories || []

      const reach = getStoriesInsights(stories, 'reach')
      const impressions = getStoriesInsights(stories, 'impressions')
      const engagement = getStoriesInsights(stories, 'engagement')
      const answers = getStoriesInsights(stories, 'answers')
      const exits = getStoriesInsights(stories, 'exits')
      const taps_back = getStoriesInsights(stories, 'taps_back')
      
      const basicInfo = {
        reach: {
          label: 'Alcance',
          insightLast30Days: counter(reach?.insightLast30Days || 0),
          insight30to60DaysAgo: counter(reach?.insight30to60DaysAgo || 0),
          difference: counter(reach?.difference || 0),
        },
        impressions: {
          label: 'Impressões',
          insightLast30Days: counter(impressions?.insightLast30Days || 0),
          insight30to60DaysAgo: counter(impressions?.insight30to60DaysAgo || 0),
          difference: counter(impressions?.difference || 0),
        },
        engagement: {
          label: 'Engajamento',
          insightLast30Days: counter(engagement?.insightLast30Days || 0),
          insight30to60DaysAgo: counter(engagement?.insight30to60DaysAgo || 0),
          difference: counter(engagement?.difference || 0),
        },
        answers: {
          label: 'Respostas',
          insightLast30Days: counter(answers?.insightLast30Days || 0),
          insight30to60DaysAgo: counter(answers?.insight30to60DaysAgo || 0),
          difference: counter(answers?.difference || 0),
        },
        exits: {
          label: 'Clique para Sair',
          insightLast30Days: counter(exits?.insightLast30Days || 0),
          insight30to60DaysAgo: counter(exits?.insight30to60DaysAgo || 0),
          difference: counter(exits?.difference || 0),
        },
        taps_back: {
          label: 'Clique para Voltar',
          insightLast30Days: counter(taps_back?.insightLast30Days || 0),
          insight30to60DaysAgo: counter(taps_back?.insight30to60DaysAgo || 0),
          difference: counter(taps_back?.difference || 0),
        },
      }
      return basicInfo
    })

    return {
      basicInfo
    }
  }
})
