
import { computed, defineComponent, toRefs, unref } from 'vue'
import BasePopover from '../base/BasePopover.vue'
import EngagedFollower from './EngagedFollower.vue'

export default defineComponent({
  components: {
    EngagedFollower,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    socialNetworkName: {
      type: String,
      default: ''
    }
  },

  setup (props) {
    const { socialNetworkData, socialNetworkName } = toRefs(props)

    const hasData = computed(() => {
      return unref(socialNetworkData) && unref(socialNetworkData).engagedPeople
    })

    const engagedFolowwers = computed(() => {
      if (unref(hasData)) {
        const engagedPeople =  unref(socialNetworkData).engagedPeople
        return engagedPeople.map((people: any) => ({
          ...people,
          source: unref(socialNetworkName)
        }))
      }
      return []
    })
   
    return {
      engagedFolowwers
    }
  }
})
