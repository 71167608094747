import { useAxios } from '@/composables/useAxios'
import axios from 'axios'
import localforage from 'localforage'

const API_URL = 'https://www.instagram.com'

const useCache = (cachedAccount: any) => {
  if (cachedAccount && cachedAccount.date) {
    const date = cachedAccount.date
    return date.setMinutes(date.getMinutes() + 10) >= new Date().getTime()
  }
  return false
}

const accountExist = async (account: string) => {
  try {
    const accountRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/img
    const valid = account.match(accountRegex)
    if (valid) {
      const response = await axios.get(`${API_URL}/${account}`)
      return response.status === 200
    }
    return false
  } catch (error) {
    return false
  }
}

const fetchInstagramData = async (account: string) => {
  if ((await accountExist(account))) {
    const userInfoSource = await axios.get(`https://www.instagram.com/${account}/`)

    const jsonObject = userInfoSource.data.match(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/)[1].slice(0, -1)

    return JSON.parse(jsonObject)
  }
}

const getAccountData = async (proileId: string) => {

  const cachedCompetitors = await localforage.getItem(`cachedInstagramCompetitors-${proileId}`) as any

  if (useCache(cachedCompetitors)) {
    return cachedCompetitors.data
  }

  const { httpFunctions } = await useAxios()
  try {
    const accountData = await httpFunctions.get(`/manySocial-competitors/instagram/${proileId}`)

    await localforage.setItem(`cachedInstagramCompetitors-${proileId}`, {
      data: accountData.data,
      date: new Date()
    })

    return accountData.data
  } catch (error) {
    console.log(error)
    return {}
  }
}

const getAccountAvatar = async (account: string) => {
  try {
    const userInfo = await fetchInstagramData(account)
    return userInfo.entry_data.ProfilePage[0].graphql.user.profile_pic_url
  } catch (e) {
    // @ts-ignore
    console.error('Unable to retrieve photos. Reason: ' + e.toString())
  }
  return null
}

export default {
  getAccountData,
  getAccountAvatar
}