
import { computed, defineComponent } from 'vue'
import Swal from "sweetalert2"
import { useNumeral } from '@/composables/useNumeral'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    account: {
      type: Object,
      default: () => ({})
    }
  },

  setup (props) {

    const {
      counter,
      format
    } = useNumeral()

    const { t } = useI18n()

    const showLegend = () => {
      Swal.fire({
        confirmButtonText: t('state.engagement_description.action_confirm'),
        html: t('state.engagement_description.message-html'),
      })
    }

    const engagementRate = computed(() => {
      return format(props.account.engagementRate, `0.00`)
    })

    const followers = computed(() => {
      return counter(props.account.followers)
    })

    const posts = computed(() => {
      return counter(props.account.posts)
    })

    const following = computed(() => {
      return counter(props.account.following)
    })

    return {
      showLegend,
      engagementRate,
      followers,
      following,
      posts
    }
  }
})
