
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import { useNumeral } from '@/composables/useNumeral'
import { computed, defineComponent, toRefs, unref } from 'vue'
import PercentageIndicator from './PercentageIndicator.vue'

export default defineComponent({
  components: { PercentageIndicator },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { socialNetworkData } = toRefs(props);
    const { getInsights } = getAnalytics();
    const { counter } = useNumeral();
    const insight = computed(() => {
      const account = unref(socialNetworkData);
      let insights = null;
      if (unref(socialNetworkData) && Object.keys(unref(socialNetworkData)).length) {
        const { insightLast30Days, insight30to60DaysAgo, difference } = getInsights(account.posts, 'likes');
        insights = {
          insightLast30Days: counter(insightLast30Days),
          insight30to60DaysAgo: counter(insight30to60DaysAgo),
          difference: difference,
        };
      }
      return insights;
    });
    return {
        insight
    };
  }
})
