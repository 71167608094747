<template>
  <div>
    <div class="card h-full relative">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.competitors.title') }}
          </div>
          <small>
            {{ $t('page.analytics.competitors.description') }}
          </small>
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.competitors.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body h-auto mb-4">
        <div
          class="grid grid-cols-1 divide-y divide-gray-200"
          v-if="competitors.length"
        >
          <instagram-public-info
            v-for="(account, index) in competitors"
            :key="index"
            :account="account"
          />
        </div>
        <div v-if="isLoading">
          {{ $t('sentence.loading') }}
        </div>
      </div>
      <div class="absolute bottom-0 card-footer flex items-center justify-end w-full">
        <small class="text-gray-500">
          {{ $t('page.analytics.competitors.sentence.update_time_info') }}
        </small>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, unref, watch } from 'vue'
import instagramApi from '@/services/instagram'
import InstagramPublicInfo from './InstagramPublicInfo.vue'
import BasePopover from '../base/BasePopover.vue'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: {
    InstagramPublicInfo,
    BasePopover
  },

  props: {
    socialNetwork: {
      type: String,
      default: undefined
    }
  },

  setup (props) {
    const { socialNetwork } = toRefs(props)

    const competitors = ref<any[]>([])
    
    const isLoading = ref(false)

    const { profile } = useProfile()

    const profileCompetitors = computed(() => {
      return unref(profile).competitors
    })

    onMounted(async () => {
      await getCompetitorsInfo()
    })

    watch([socialNetwork, profileCompetitors], async () => {
      await getCompetitorsInfo()
    })

    const getCompetitorsInfo = async () => {
      if (profileCompetitors.value === undefined) {
        return
      }

      isLoading.value = true

      competitors.value = []

      const igCompetitors = (await instagramApi.getAccountData(unref(profile).id)) as any

      if (igCompetitors.length > 0) {
        competitors.value = igCompetitors.map((account: any) => {
          
          let engagementRate = 0
          
          if (account.media && account.media.data && account.media.data.length) {
            const last12Posts = account.media.data.slice(0, 12)

            const engagement = last12Posts.reduce((prev: any, curr: any) => {
              const comments = curr.comments_count || 0
              const likes = curr.like_count || 0
              return prev + comments + likes
            }, 0)

            engagementRate = ((engagement / 12) / account.followers_count) * 100
          }
          
          return {
            image: account.profile_picture_url,
            followers: account.followers_count,
            following: account.follows_count,
            posts: account.media_count,
            username: account.username,
            engagementRate,
            insideRange: isEngagementRateInsideRage(account.followers_count, engagementRate)
          }
        })
      }

      isLoading.value = false
    }

    const isEngagementRateInsideRage = (followers: number, rate: number) => {
      if (followers < 1000) {
        return rate > 8
      }

      if (followers < 5000) {
        return rate > 5.7
      }

      if (followers < 10000) {
        return rate > 4
      }

      if (followers < 100000) {
        return rate > 2.4
      }

      return rate > 1.7
    }

    return {
      isLoading,
      competitors,
      profileCompetitors
    }
  }
})
</script>
