<template>
  <div>
    <div class="card h-full">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.reaction_to_content.title') }}
          </div>
          <small>
            {{ $t('page.analytics.reaction_to_content.description') }}
          </small>
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.reaction_to_content.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body">
        <div v-if="hasData" class="flex flex-col space-y-8 my-6">
          <div>
            <label>
              {{ $t('page.analytics.reaction_to_content.sentence.positive_reactions') }} {{ format(sentiment.positive.percent, '0.0%') }}
            </label>
            <div class="progress border border-gray-200 h-2 shadow-md rounded-full mt-2">
              <div
                class="progress-bar rounded-full bg-green-400"
                :style="{ width: sentiment.positive.percent * 100 + '%' }"
              />
            </div>
          </div>
          <div>
            <label>
              {{ $t('page.analytics.reaction_to_content.sentence.neutral_reactions') }} {{ format(sentiment.null.percent, '0.0%') }}
            </label>
            <div class="progress border border-gray-200 h-2 shadow-md rounded-full mt-2">
              <div
                class="progress-bar rounded-full bg-yellow-400"
                :style="{ width: sentiment.null.percent * 100 + '%' }"
              />
            </div>
          </div>
          <div>
            <label>
              {{ $t('page.analytics.reaction_to_content.sentence.negative_reactions') }} {{ format(sentiment.negative.percent, '0.0%')}}
            </label>
            <div class="progress border border-gray-200 h-2 shadow-md rounded-full mt-2">
              <div
                class="progress-bar rounded-full bg-red-600"
                :style="{ width: sentiment.negative.percent * 100 + '%' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useNumeral } from '@/composables/useNumeral'
import { computed, defineComponent, toRefs, unref } from 'vue'
import BasePopover from '../base/BasePopover.vue'

export default defineComponent({
  components: { BasePopover },
  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const {
      format
    } = useNumeral()
    
    const hasData = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).sentment) {
        const sentment = unref(socialNetworkData).sentment
        return sentment.total > 0
      }
      return false
    })

    const sentment = computed(() => {
      const result = {
        positive: {
          value: 0,
          percent: 0
        },
        negative: {
          value: 0,
          percent: 0
        },
        null: {
          value: 0,
          percent: 0
        }
      }
      if (unref(hasData)) {
        const sentment = unref(socialNetworkData).sentment
        if (sentment.total > 0) {
          result.positive.value = sentment.positive
          result.positive.percent = ((result.positive.value) / sentment.total)
          result.negative.value = sentment.negative
          result.negative.percent = ((result.negative.value) / sentment.total)
          result.null.value = sentment.mixed + sentment.null
          result.null.percent = ((result.null.value) / sentment.total)
        }
      }
      return result
    })

    return {
      hasData,
      sentiment: sentment,
      format
    }
  }
})
</script>
