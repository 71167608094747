<template>
  <div>
    <div class="card h-full">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.engaged_followers.title') }}
          </div>
          <small>
            {{ $t('page.analytics.engaged_followers.description') }}
          </small>
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.engaged_followers.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body">
        <engaged-follower
          v-for="account in engagedFolowwers"
          :key="account.username"
          :account="account"
          :socialNetworkName="socialNetworkName"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, unref } from 'vue'
import BasePopover from '../base/BasePopover.vue'
import EngagedFollower from './EngagedFollower.vue'

export default defineComponent({
  components: {
    EngagedFollower,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    socialNetworkName: {
      type: String,
      default: ''
    }
  },

  setup (props) {
    const { socialNetworkData, socialNetworkName } = toRefs(props)

    const hasData = computed(() => {
      return unref(socialNetworkData) && unref(socialNetworkData).engagedPeople
    })

    const engagedFolowwers = computed(() => {
      if (unref(hasData)) {
        const engagedPeople =  unref(socialNetworkData).engagedPeople
        return engagedPeople.map((people: any) => ({
          ...people,
          source: unref(socialNetworkName)
        }))
      }
      return []
    })
   
    return {
      engagedFolowwers
    }
  }
})
</script>
