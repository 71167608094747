<template>
  <div>
    <div class="card">
      <div class="card-body pt-4 flex flex-col">
        <div class="flex flex-col items-center justify-center gap-4">
          <div class="flex gap-2 items-center">
            <i class="fa fa-comments text-2xl text-green-400"></i>
            <span class="text-gray-600">{{ $t('sentence.comments') }}</span>
          </div>
          <div class="text-center" v-if="insight">
            <div class="flex gap-2 justify-center">
              <h2>{{ insight.insightLast30Days }}</h2>
              <percentage-indicator :value="insight.difference" />
            </div>
            <small class="text-gray-500"><span class="font-semibold">{{ insight?.insight30to60DaysAgo }}</span> no período anterior</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import { useNumeral } from '@/composables/useNumeral'
import { computed, defineComponent, toRefs, unref } from 'vue'
import PercentageIndicator from './PercentageIndicator.vue'

export default defineComponent({
  components: { PercentageIndicator },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { socialNetworkData } = toRefs(props);
    const { getInsights } = getAnalytics();
    const { counter } = useNumeral();
    const insight = computed(() => {
      const account = unref(socialNetworkData);
      let insights = null;
      if (unref(socialNetworkData) && Object.keys(unref(socialNetworkData)).length) {
        const { insightLast30Days, insight30to60DaysAgo, difference } = getInsights(account.posts, 'comments');
        insights = {
          insightLast30Days: counter(insightLast30Days),
          insight30to60DaysAgo: counter(insight30to60DaysAgo),
          difference: difference,
        };
      }
      return insights;
    });
    return {
        insight
    };
  }
})
</script>
