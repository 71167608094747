
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import AnalyticsPageMenu from '@/components/analytics/AnalyticsPageMenu.vue'
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import EngagementChart from '@/components/analytics/EngagementChart.vue'
import NetworkDistributionChart from '@/components/analytics/networkDistributionChart.vue'
// import FollowersCount from '@/components/analytics/FollowersCount.vue'
import ReachCount from '@/components/analytics/ReachCount.vue'
import ImpressionsCount from '@/components/analytics/ImpressionsCount.vue'
import SavedContentCount from '@/components/analytics/SavedContentCount.vue'
// import ContentCount from '@/components/analytics/ContentCount.vue'
import EngagementRate from '@/components/analytics/EngagementRate.vue'
import CommentsCount from '@/components/analytics/CommentsCount.vue'
import LikesCount from '@/components/analytics/LikesCount.vue'
import ReactionToContent from '@/components/analytics/ReactionToContent.vue'
import HistoryOfPublicationTimes from '@/components/analytics/historyOfPublicationTimes.vue'
import HistoryOfHashtags from '@/components/analytics/HistoryOfHashtags.vue'
import FollowersMetrics from '@/components/analytics/FollowersMetrics.vue'
import MediaTypeDistributionChart from '@/components/analytics/MediaTypeDistributionChart.vue'
import MediaTypeEngagementChart from '@/components/analytics/MediaTypeEngagementChart.vue'
import AudienceGender from '@/components/analytics/AudienceGender.vue'
import AudienceAge from '@/components/analytics/AudienceAge.vue'
import AudienceCity from '@/components/analytics/AudienceCity.vue'
import BestPosts from '@/components/analytics/BestPosts.vue'
import Competitors from '@/components/analytics/Competitors.vue'
import EngagedFollowers from '@/components/analytics/EngagedFollowers.vue'
import BasicInfo from '@/components/analytics/BasicInfo.vue'
import StoriesBasicInfo from '@/components/analytics/StoriesBasicInfo.vue'
import Stories from '@/components/analytics/Stories.vue'
import { useProfile } from '@/composables/profile/useProfile'
import OfferModal from '@/components/freeTrial/OfferModal.vue'
import OfferAlertWrapper from '@/components/freeTrial/OfferAlertWrapper.vue'
import { useI18n } from 'vue-i18n'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'
import { useStore } from 'vuex'

interface IAnalytics {
  facebook: string
  instagram: string
  linkedin: string
  twitter: string
}

export default defineComponent({
  components: {
    DashboardContent,
    AnalyticsPageMenu,
    EngagementChart,
    NetworkDistributionChart,
    MediaTypeDistributionChart,
    MediaTypeEngagementChart,
    // FollowersCount,
    ReachCount,
    ImpressionsCount,
    SavedContentCount,
    // ContentCount,
    EngagementRate,
    CommentsCount,
    LikesCount,
    ReactionToContent,
    HistoryOfPublicationTimes,
    HistoryOfHashtags,
    FollowersMetrics,
    AudienceGender,
    AudienceAge,
    AudienceCity,
    BestPosts,
    Competitors,
    EngagedFollowers,
    OfferModal,
    OfferAlertWrapper,
    BaseYouNeedHelpMessage,
    BasicInfo,
    StoriesBasicInfo,
    Stories
},

  setup () {
    const selectedNetworkName = ref<string>('')

    const { t } = useI18n()

    const store = useStore()

    const {
      analytics,
      isLoaded,
      isEmpty
    } = getAnalytics()

    const {
      isFreeTrial,
      isFreeTrialActive,
      hasActiveSubscription,
      lockAccess,
      isActive
    } = useProfile()

    watchEffect(() => {
      store.dispatch('analytics/fetchAnalytics', isActive.value)
    })

    const selectedNetworkData = computed(() => {
      if (unref(selectedNetworkName) === '') return

      const key = unref(selectedNetworkName) as keyof IAnalytics
      return unref(analytics)[key]
    })

    const hasSelectedSocialNetwork = computed(() => {
      return unref(selectedNetworkName) !== ''
    })

    const alertMessage = computed(() => {
      if (isFreeTrial.value && !isFreeTrialActive.value) {
        return t('state.finish_free_trial')
      } else if (!hasActiveSubscription.value) {
        return t('state.subscription_expired')  
      }
      return ''
    })

    return {
      hasSelectedSocialNetwork,
      selectedNetworkName,
      selectedNetworkData,
      analytics,
      isLoaded,
      isEmpty,
      alertMessage,
      lockAccess
    }
  }
})
