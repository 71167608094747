<template>
  <div class="card flex flex-col relative select-none cursor-move">
    <div 
      class="rounded-tl-lg rounded-tr-lg"
    >
      <img :src=thumbnail alt="" className='rounded-tl-lg rounded-tr-lg' @error="imageFallback" />
    </div>
    <div class="card-body mt-2 overflow-y-auto">
      <div class="text-sm text-right">
        {{ publishDate }}
      </div>
      <div class="mt-6">
        <div
          v-for="(value, key) in metrics" :key="key"
          class="flex justify-between"
        >
          <div>{{ value.label }}</div><div class="font-semibold">{{ value.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRefs, unref } from 'vue'
import { useDateFns } from '@/composables/locale/useDateFns'
import { useNumeral } from '@/composables/useNumeral'
import { useProfile } from '@/composables/profile/useProfile'
import { getAnalytics } from '@/composables/analytics/useAnalytics'

export default defineComponent({
  components: {
  },

  props: {
    post: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { post } = toRefs(props)

    const { format } = useDateFns()

    const { profile } = useProfile()

    const {
      fetchPostThumbnail
    } = getAnalytics()

    const {
      counter
    } = useNumeral()

    const thumbnail = ref('')
    const type = ref('')

    const publishDate = computed(() => {
      return format(new Date(unref(post).date))
    })

    const metrics = computed(() => {
      return {
        reach: {
          label: 'Alcance',
          value: counter(unref(post).reach || 0),
        },
        retention: {
          label: 'Retenção',
          value: counter(unref(post).retention || 0),
        },
        impressions: {
          label: 'Impressões',
          value: counter(unref(post).impressions || 0),
        },
        tap_back: {
          label: 'Toque para voltar',
          value: counter(unref(post).tap_back || 0),
        },
        exit: {
          label: 'Toque para sair',
          value: counter(unref(post).exit || 0),
        },
        engagement: {
          label: 'Engajamento',
          value: counter(unref(post).engagement || 0),
        },
        answers: {
          label: 'Respostas',
          value: counter(unref(post).answers || 0),
        },
        referrals: {
          label: 'Indicações',
          value: counter(unref(post).referrals || 0),
        },
      }
    })

    onMounted(() => {
      type.value = unref(post).media?.type
      thumbnail.value =  unref(post).media?.thumbnail || unref(post).media?.url || require('@/assets/img/no-image.png')
    })

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/img/no-image.png')

      try {
        const socialId = unref(post).id.split('_')[0]
        const thumb = await fetchPostThumbnail(profile.value.id, socialId, 'instagram')
        if (thumb) {
          element.src = thumb 
        }
      } catch (error) {
        console.log(error)
      }
    }

    return {
      thumbnail,
      imageFallback,
      publishDate,
      metrics,
    }
  }
})
</script>

<style scoped>
.square {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
