<template>
  <div>
    <div class="card h-full">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('sentence.followers') }}
          </div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="hasData" class="flex flex-col space-y-8 my-6">
          <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n';
import VueApexCharts from "vue3-apexcharts";
import socialNetworkColor from '@/composables/socialNetworks/socialNetworkColor'
import { useDateFns } from '@/composables/locale/useDateFns';
import { toDate } from '@/composables/utils/toDate';
import { useNumeral } from '@/composables/useNumeral';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    socialNetworkName: {
      type: String,
      default: undefined
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { t } = useI18n()

    const { format } = useDateFns()

    const { counter } = useNumeral();
    
    const genders = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).extra) {
        const insights = unref(socialNetworkData).extra
        if (insights && insights['audience_gender_age']) {
          return insights['audience_gender_age'].values[0].value
        }
      }
      return []
    })

    const hasData = computed(() => {
      return unref(genders) && Object.keys(unref(genders)).length
    })

    const series = computed(() => {
      const currentMetrics = unref(socialNetworkData).followerMetrics

      const dailyData = [
        {
          name: 'Seguidores',
          data: currentMetrics?.daily.map((item: {followersCount: number}) => item.followersCount) || [],
          labels: currentMetrics?.daily.map((item: {date: any}) => toDate(item.date)) || [],
          color: socialNetworkColor(unref(props.socialNetworkName))
        }
      ]

      return dailyData
    })

    const dates = computed(() => {
      return series.value.length
        ? series.value[0]?.labels?.map((date: Date) => format(date, 'dd/MM/yyyy'))
        : []
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: unref(series.value).map((g: any) => g.color),
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: t('sentence.followers'),
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        labels: unref(dates),
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y: number) {
              if (typeof y !== "undefined") {
                return counter(y)
              }
              return y
            }
          }
        }
      }
    })
    
    return {
      hasData,
      series,
      chartOptions,
    }
  }
})
</script>
