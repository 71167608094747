
import { useNumeral } from '@/composables/useNumeral'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  
  props: {
    value: {
      type: Number,
      default: 0
    },
  },

  setup (props) {
    const {
      format
    } = useNumeral()

    const percentage = computed(() => {
      return props.value !== Infinity ? format(props.value / 100, '0.00%') : 0
    })

    return {
      percentage
    }
  }
})
