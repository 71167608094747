<template>
  <div
    class="flex items-center gap-1 badge text-xs text-green-600 bg-green-100"
    v-if="value >= 0"
  >
    <i class="fas fa-sort-up text-green-600"></i> {{ percentage }}
  </div>
  <div
    class="flex items-center gap-1 badge text-xs text-red-700 bg-red-100"
    v-else
  >
    <i class="fas fa-sort-down text-red-700"></i> {{ percentage }}
  </div>
</template>

<script lang="ts">
import { useNumeral } from '@/composables/useNumeral'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  
  props: {
    value: {
      type: Number,
      default: 0
    },
  },

  setup (props) {
    const {
      format
    } = useNumeral()

    const percentage = computed(() => {
      return props.value !== Infinity ? format(props.value / 100, '0.00%') : 0
    })

    return {
      percentage
    }
  }
})
</script>