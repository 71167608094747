<template>
  <div>
    <div class="card h-full">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.audience_age.title') }}
          </div>
          <small>
            {{ $t('page.analytics.audience_age.description') }}
          </small>
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.audience_age.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body">
        <div v-if="hasData" class="flex flex-col space-y-8 my-6">
          <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useNumeral } from '@/composables/useNumeral';
import { computed, defineComponent, toRefs, unref } from 'vue'
import { useI18n } from 'vue-i18n';
import VueApexCharts from "vue3-apexcharts";
import BasePopover from '../base/BasePopover.vue';

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { t } = useI18n()

    const {
      counter
    } = useNumeral()
    
    const genders = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).extra) {
        const insights = unref(socialNetworkData).extra
        if (insights && insights['audience_gender_age']) {
          return insights['audience_gender_age'].values[0].value
        }
      }
      return []
    })

    const hasData = computed(() => {
      return unref(genders) && Object.keys(unref(genders)).length
    })

    const series = computed(() => {
      const keysOrder = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']
      const gendersResult = [{
        name: t('sentence.male'),
        key: 'male',
        data: [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: t('sentence.feminine'),
        key: 'feminine',
        data: [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: t('sentence.undefined'),
        key: 'undefined',
        data: [0, 0, 0, 0, 0, 0, 0]
      }]
      
      Object.keys(unref(genders)).map(key => {
        const sub = key.split('.')
        const gender = sub[0]
        const age = sub[1]
        switch (gender) {
          case 'M': {
            const a = gendersResult.find(s => s.key === 'male')
            if (a) {
              a.data[keysOrder.indexOf(age)] = unref(genders)[key]
            }
            break
          }
          case 'F': {
            const a = gendersResult.find(s => s.key === 'feminine')
            if (a) {
              a.data[keysOrder.indexOf(age)] = unref(genders)[key]
            }
            break
          }
          case 'U': {
            const a = gendersResult.find(s => s.key === 'undefined')
            if (a) {
              a.data[keysOrder.indexOf(age)] = unref(genders)[key]
            }
            break
          }
        }
      })
      return gendersResult
    })

    const chartOptions = computed(() => {

      const formatter = (value: number) => {
        if (typeof value !== "undefined") {
          return counter(value)
        }
        return value
      }

      return {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        dataLabels: {
          formatter
        },
        colors: ['#008ffb', '#d10ce8', '#feb019'],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: ['13-17', '18-24', '25-34', '35-44', '45/54', '55-64', '+65'],
          title: {
            text: t('sentence.followers')
          },
          labels: {
            formatter
          }
        },
        yaxis: {
          title: {
            text: t('sentence.age')
          },
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 20
        },
        tooltip: {
          y: {
            formatter
          }
        }
      }
    })
    
    return {
      hasData,
      series,
      chartOptions,
    }
  }
})
</script>
