
import { useNumeral } from '@/composables/useNumeral'
import { computed, defineComponent, toRefs, unref } from 'vue'
import PercentageIndicator from './PercentageIndicator.vue'
import { useI18n } from 'vue-i18n'
import Swal from "sweetalert2"

interface EngagementMetrics {
  engagementRate: number
  engagementCount: number
  difference: number
}

interface EngagementEvolution {
  month: string
  sum: number
}

function calculatePercentageDifference(valueA: number, valueB: number): number {
  return ((valueB - valueA) / valueA) * 100;
}

const getEngagementRateMetrics = (account: any) :EngagementMetrics => {
  const engagementData: EngagementEvolution[] = account?.engagementEvolution?.map((item: EngagementEvolution) => {
      return {
        ...item,
        date: new Date(`${item.month.substring(0, 4)}/${item.month.substring(4, 6)}/01`),
      }
    })
    .sort((a: {date: Date}, b: {date: Date}) => b.date.getTime() - a.date.getTime())

  const output: EngagementMetrics = {
    engagementRate: account?.engagementRate || 0,
    engagementCount: engagementData?.[0].sum || 0,
    difference: calculatePercentageDifference(engagementData?.[1]?.sum || 0, engagementData?.[0]?.sum || 0)
  }
  return output
}

export default defineComponent({
  components: { PercentageIndicator },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { socialNetworkData } = toRefs(props);

    const {
      format,
      counter
    } = useNumeral()

    const { t } = useI18n()

    const insight = computed(() => {
      const account = unref(socialNetworkData);
      const {engagementRate, engagementCount, difference} = getEngagementRateMetrics(account)
      return {
        engagementRate: format((engagementRate || 0) / 100, '0.00%'),
        engagementCount: counter(engagementCount || 0),
        difference
      }
    });

    const showLegend = () => {
      Swal.fire({
        confirmButtonText: t('state.engagement_description.action_confirm'),
        html: t('state.engagement_description.message-html'),
      })
    }

    return {
        insight,
        showLegend
    };
  }
})
