
import { computed, defineComponent, toRefs, unref } from 'vue'
import StoryView from './StoryView.vue'

// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'
import BasePopover from '../base/BasePopover.vue'
import { toDate } from '@/composables/utils/toDate'

// install Swiper modules
SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    StoryView,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    dataOnly: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { socialNetworkData } = toRefs(props)
    
    const posts = computed(() => {
      const account = unref(socialNetworkData)
      return (account.tmp?.stories || []).map((story: any) => ({
        ...story,
        date: story.date ? toDate(story.date) : new Date()
      }))
       
    })

    const swiperSettings = {
      loop: false,
      slidesPerView: 1,
      slideToClickedSlide: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      breakpoints: {
        640: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 4
        },
        1536: {
          slidesPerView: 5
        }
      }
    }

    return {
      posts,
      swiperSettings
    }
  }
})
